import { ArrowRight } from 'lucide-react'
import ChatbotRequestModal from './chatbot-request'
import { ModalTrigger } from './ui/animated-modal'
import { ConfettiButton } from './magicui/confetti'
import { cn } from '@/lib/utils'

export default function PricingCTA({
  index,
  plan
}: {
  index: number
  plan: '1' | '2' | 'custom'
}) {
  return (
    <div className="flex flex-col gap-1.5">
      <ChatbotRequestModal
        from="pricing"
        defaultNote={
          plan === '1'
            ? 'Az "Alap" csomag érdekelne.'
            : plan === '2'
              ? 'A "Pro" csomag érdekelne.'
              : 'Egyedi ajánlatot szeretnék majd kérni.'
        }>
        <ModalTrigger>
          <ConfettiButton
            onClick={() => {
              document.cookie = 'chatbot-request-from=pricing'
            }}
            className="bg-transparent hover:bg-transparent">
            <span
              className={cn(
                'cursor-pointer relative gap-1 rounded-xl text-slate-700  ease-in-out border border-slate-300 hover:-mt-1 hover:mb-1 transition-all text-sm md:text-base px-4 md:px-6 flex py-2 justify-center items-center fong-medium',

                index === 2
                  ? 'bg-gradient-to-r from-violet-600 to-indigo-500 text-white'
                  : 'bg-white'
              )}>
              Ingyenes Demó
              <ArrowRight
                className="ml-1 mt-[1px] size-5 transition-transform duration-300"
                strokeWidth={2.2}
              />
            </span>
          </ConfettiButton>
        </ModalTrigger>
      </ChatbotRequestModal>
      <p className="-mt-2 text-xs text-slate-400 ease-in-out text-center">
        * Bankkártya megadása nélkül
      </p>
    </div>
  )
}
